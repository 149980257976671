import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";

interface Props extends ButtonProps {
  children: string;
  submittingCaption?: string;
}

export default function SubmitButton({ children, submittingCaption, ...props }: Props): ReactElement {
  const { isSubmitting } = useFormikContext();
  return (
    <Button {...props} disabled={isSubmitting} type="submit">
      {isSubmitting ? submittingCaption || children : children}
    </Button>
  );
}
